import React from "react";
import { Form, Container } from "semantic-ui-react";
import PropTypes from "prop-types";

const JobPostingField = (props) => {
  switch (props.field.type) {
    case "free_text":
      return (
        <Container>
          {props.field.value}
          <Form.Input
            width={7}
            required={props.required}
            onChange={(event) => {
              props.mappingId !== undefined
                ? props.handleFieldChangeWithMappingId(
                    props.mappingId,
                    props.id,
                    event.target.value
                  )
                : props.handleFieldChange(props.id, event.target.value);
            }}
          />
        </Container>
      );
  }
};

JobPostingField.props = {
  id: PropTypes.number.isRequired,
  handleFieldChange: PropTypes.func,
  handleFieldChangeWithMappingId: PropTypes.func,
  mappingId: PropTypes.number,
  required: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired,
};

export default JobPostingField;
