import { gql } from "@apollo/client";

export const ADD_JOB_POSTING_TO_CART_MUTATION = gql`
  mutation Mutation($jobPostingId: Int!) {
    addJobPostingToCart(jobPostingId: $jobPostingId) {
      jobCartEntry {
        jobId
      }
    }
  }
`;

export const REMOVE_JOB_POSTING_FROM_CART_MUTATION = gql`
  mutation Mutation($jobPostingId: Int!) {
    removeJobPostingFromCart(jobPostingId: $jobPostingId) {
      jobCartEntry {
        jobId
      }
    }
  }
`;
export const BULK_REMOVE_JOB_POSTINGS_FROM_CART_MUTATION = gql`
  mutation Mutation($jobPostingIds: [Int!]) {
    bulkRemoveJobPostingsFromCart(jobPostingIds: $jobPostingIds) {
      jobCartEntry {
        jobId
      }
    }
  }
`;
export const APPLY_FOR_JOB_POSTING = gql`
  mutation Mutation($applicationInput: ApplicationInput!) {
    applyForJobPosting(applicationInput: $applicationInput) {
      application {
        id
        status
        jobPosting {
          id
          role
          team
          employer {
            employerName
          }
          location {
            city
            state
            zipCode
          }
        }
      }
    }
  }
`;

export const BULK_APPLY_FOR_JOB_POSTINGS = gql`
  mutation Mutation($bulkApplicationInput: BulkApplicationInput!) {
    bulkApplyForJobPostings(bulkApplicationInput: $bulkApplicationInput) {
      applications {
        id
        status
        jobPosting {
          id
          role
          team
          employer {
            employerName
          }
          location {
            city
            state
            zipCode
          }
        }
      }
    }
  }
`;

export const UPDATE_APPLICATION_STATUS_BY_APPLICATION_ID_MUTATION = gql`
  mutation Mutation($applicationId: Int!, $newStatus: String!) {
    updateApplicationStatusByApplicationId(
      applicationId: $applicationId
      newStatus: $newStatus
    ) {
      application {
        id
      }
    }
  }
`;


export const UPDATE_APPLICANT_BASIC_ACCOUNT_INFO_MUTATION = gql`
  mutation Mutation($applicantInfo: UserBasicInfoInput!) {
    updateApplicantBasicAccountInfo(
      applicantInfo : $applicantInfo
    ) {
      user {
        firstName
        lastName
        emailAddress
      }
    }
  }
`;