import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  Container,
  Header,
  Segment,
  Item,
  Tab,
  Button,
} from "semantic-ui-react";
import {
  getSuccessToastWithMessage,
  getFailureToastWithMessage,
} from "../shared/toast/ToastOptions";
import { UPDATE_APPLICATION_STATUS_BY_APPLICATION_ID_MUTATION } from "../../services/graphql/mutations/ApplicantMutations";

const MyApplicationsView = (props) => {
  useEffect(() => {
    const currentAppliedJobs = props.applications.filter((application) => {
      return application.status === "applied";
    });
    setAppliedJobs(currentAppliedJobs);

    const currentInterviewingJobs = props.applications.filter((application) => {
      return application.status === "interviewing";
    });
    setInterviewingJobs(currentInterviewingJobs);

    const currentRejectedJobs = props.applications.filter((application) => {
      return application.status === "rejected";
    });
    setRejectedJobs(currentRejectedJobs);

    const currentWithdrawnJobs = props.applications.filter((application) => {
      return application.status === "withdrawn";
    });
    setWithdrawnJobs(currentWithdrawnJobs);
  }, [props.applications]);

  const [
    updateApplicationStatusByApplicationId,
    { mutationData },
  ] = useMutation(UPDATE_APPLICATION_STATUS_BY_APPLICATION_ID_MUTATION);

  const [appliedJobs, setAppliedJobs] = useState([]);
  const [interviewingJobs, setInterviewingJobs] = useState([]);
  const [rejectedJobs, setRejectedJobs] = useState([]);
  const [withdrawnJobs, setWithdrawnJobs] = useState([]);

  const getJobPostingHeader = (jobPosting) => {
    return (
      jobPosting.role +
      (jobPosting.team !== null ? " | " + jobPosting.team : "")
    );
  };
  const formattedJobLocation = (jobPosting) =>
    `${jobPosting.location?.city}, ${jobPosting.location?.state}`;

  const generateApplicationItems = (applications) => {
    return (
      <Item.Group link divided>
        {applications.map((application, index) => {
          return generateApplicationItem(application, index);
        })}
      </Item.Group>
    );
  };

  const generateAppliedApplicationItems = (applications) => {
    return (
      <Item.Group link divided>
        {applications.map((application, index) => {
          return generateAppliedApplicationItem(application, index);
        })}
      </Item.Group>
    );
  };

  const generateApplicationItem = (application, key) => {
    return (
      <Item key={key}>
        <Item.Content>
          <Header as="h3">{getJobPostingHeader(application.jobPosting)}</Header>
          <Item.Description>
            {application.jobPosting.employer.employerName}
          </Item.Description>
          <Item.Meta>{formattedJobLocation(application.jobPosting)}</Item.Meta>
        </Item.Content>
      </Item>
    );
  };

  const generateAppliedApplicationItem = (application, key) => {
    return (
      <Item key={key}>
        <Item.Content>
          <Header as="h3">{getJobPostingHeader(application.jobPosting)}</Header>
          <Item.Description>
            {application.jobPosting.employer.employerName}
          </Item.Description>
          <Item.Meta>{formattedJobLocation(application.jobPosting)}</Item.Meta>
        </Item.Content>
        <Item.Content>
          <Button
            floated="right"
            onClick={() => handleWithdrawApplication(application.id)}
          >
            Withdraw
          </Button>
        </Item.Content>
      </Item>
    );
  };

  const handleWithdrawApplication = (applicationId) => {
    updateApplicationStatusByApplicationId({
      variables: {
        applicationId,
        newStatus: "withdrawn",
      },
    })
      .then(() => {
        props.withdrawApplication(applicationId);
        props.openToast(
          getSuccessToastWithMessage("Successfully withdrew application")
        );
      })
      .catch((error) => {
        console.log(error);
        props.openToast(
          getFailureToastWithMessage("Failed to withdraw application!")
        );
      });
  };

  const panes = [
    {
      menuItem: `Applied (${appliedJobs.length})`,
      render: () => (
        <Tab.Pane attached={false}>
          {generateAppliedApplicationItems(appliedJobs)}
        </Tab.Pane>
      ),
    },
    {
      menuItem: `Interviewing (${interviewingJobs.length})`,
      render: () => (
        <Tab.Pane attached={false}>
          {generateApplicationItems(interviewingJobs)}
        </Tab.Pane>
      ),
    },
    {
      menuItem: `Rejected (${rejectedJobs.length})`,
      render: () => (
        <Tab.Pane attached={false}>
          {generateApplicationItems(rejectedJobs)}
        </Tab.Pane>
      ),
    },
    {
      menuItem: `Withdrawn (${withdrawnJobs.length})`,
      render: () => (
        <Tab.Pane attached={false}>
          {generateApplicationItems(withdrawnJobs)}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container fluid>
      <Segment>
        <Header>My Applications</Header>
      </Segment>
      <Segment>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Segment>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    applications: state.applicant.applications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openToast: (toast) => dispatch({ type: "OPEN_TOAST", payload: toast }),
    withdrawApplication: (applicationId) =>
      dispatch({ type: "WITHDRAW_APPLICATION", payload: applicationId }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyApplicationsView);
