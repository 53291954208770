export const jobPostingDescriptionToolbar = {
    options: [
      "inline",
      "blockType",
      "list",
      "link",
      "embedded",
      "image",
      "remove",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
  }