import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Message,
  Loader,
} from "semantic-ui-react";
import { GET_JOB_POSTING_BY_ID } from "../../../services/graphql/queries/ApplicantQueries";
import ApplicationView from "./ApplicationView";

const SingleApplicationView = (props) => {
  const jobId = props.match.params.jobId;
  const [redirect, setRedirect] = useState(false);

  const { loading, error, data } = useQuery(GET_JOB_POSTING_BY_ID, {
    variables: {
      jobPostingId: jobId,
    },
  });

  if (error) {
    return (
      <Message content="Error loading Job Application Information!" negative />
    );
  } else if (loading) {
    return <Loader active></Loader>;
  } else if (redirect) {
    return <Redirect to="/applicant/applicant-console" />;
  } else {
    return <ApplicationView jobPosting={data.jobPostingById} setRedirect={setRedirect}/>;
  }
};

export default SingleApplicationView
