import React, { useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container, Button, Segment } from "semantic-ui-react";
import "./css/PdfView.css";

const PdfView = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*When document gets loaded successfully*/
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <Container fluid textAlign="center">
      <Segment>

          <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page scale={1.7} pageNumber={pageNumber} />
          </Document>
          <div className="pagec">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </div>
          <Button disabled={pageNumber <= 1} onClick={previousPage}>
            Previous
          </Button>
          <Button disabled={pageNumber >= numPages} onClick={nextPage}>
            Next
          </Button>

      </Segment>
    </Container>
  );
};

export default PdfView;
