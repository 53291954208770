export const updateJobPostingService = (jobPostingInfo) => {
  return fetch("https://api.applomb.io/update_job_posting", {
    method: "POST",
    body: JSON.stringify(jobPostingInfo),
  });
};

export const deleteJobPostingService = (jobId) => {
  return fetch("https://api.applomb.io/delete_job_posting", {
    method: "POST",
    body: JSON.stringify(jobId),
  });
};

export const loadJobPostingFieldsService = () => {
  return fetch("https://api.applomb.io/get_job_posting_fields");
};

export const loadJobApplicantsService = (jobId) => {
  return fetch("https://api.applomb.io/load_job_applications_by_job_id", {
    method: "POST",
    body: JSON.stringify(jobId),
  });
};

export const fetchFile = (s3Bucket, s3Key) => {
  return fetch("https://api.applomb.io/fetch_file_by_bucket_and_key", {
    method: "POST",
    body: JSON.stringify({ s3Bucket, s3Key }),
  });
};
