import { loginUserService } from "../../../services/AccountServices";
import React, { useState } from "react";
import { Button, Card, Form, Item, Header } from "semantic-ui-react";

import "./css/UserLogin.css";

function UserLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  const onEmailChange = (input) => {
    setEmail(input.target.value);
  };

  const onPasswordChange = (input) => {
    setPassword(input.target.value);
  };

  const handleSubmitButtonClick = (event) => {
    loginUserService(email, password, 2)
      .then((response) => {
        response.json().then((data) => {
          if (data["hasError"] === true) {
            setValidationMessage(data["errorMessage"]);
          } else {
            if (data["userType"] === 1) {
              window.location.assign("/applicant/applicant-console");
            } else {
              window.location.assign("/employer/employer-console");
            }
          }
        });
      })
      .catch((error) => {
        setValidationMessage(error);
      });
  };

  return (
    <div className="userLoginForm">
      <Card.Group centered>
        <Card>
          <Card.Header textAlign="center">
            <Item.Group link>
              <Item href={"/"}>
                <Item.Content>
                  <Item.Header>Applomb</Item.Header>
                </Item.Content>
              </Item>
            </Item.Group>
          </Card.Header>
          <Card.Content>
            <Header content="Log In"></Header>
            <Form onSubmit={handleSubmitButtonClick}>
              <Form.Field
                required
                label="Email Address"
                control="input"
                name="email"
                type="email"
                onChange={(input) => onEmailChange(input)}
                value={email}
                placeholder="Enter an Email Address"
              />
              <Form.Field
                name="password"
                required
                label="Password"
                control="input"
                onChange={(input) => onPasswordChange(input)}
                value={password}
                type="password"
                placeholder="Password"
              />
              <Button primary fluid type="submit">
                Login
              </Button>
            </Form>
          </Card.Content>
          {validationMessage.length !== 0 && (
            <div className="alert alert-danger" role="alert">
              {validationMessage}
            </div>
          )}
        </Card>
      </Card.Group>
    </div>
  );
}

export default UserLogin;
