import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { Container, Segment, Button, Header, Loader } from "semantic-ui-react";
import PersonalInfoForm from "./PersonalInfoForm";
import {
  getSuccessToastWithMessage,
  getFailureToastWithMessage,
} from "../../shared/toast/ToastOptions";
import { uploadFiles } from "../../../services/applicant/ApplicantServices";
import { BULK_APPLY_FOR_JOB_POSTINGS } from "../../../services/graphql/mutations/ApplicantMutations";
import JobPostingField from "./JobPostingField";
import { getJobPostingHeader } from "../../shared/jobPostings/JobPostingUtilities";
import { BULK_REMOVE_JOB_POSTINGS_FROM_CART_MUTATION } from "../../../services/graphql/mutations/ApplicantMutations";

const BulkApplicationView = (props) => {
  const [bulkApplyForJobPostings, { applicationMutationData }] = useMutation(
    BULK_APPLY_FOR_JOB_POSTINGS
  );
  const [
    bulkRemoveJobPostingsFromCart,
    { bulkRemoveJobPostingsMutationData },
  ] = useMutation(BULK_REMOVE_JOB_POSTINGS_FROM_CART_MUTATION);

  const [personalInfo, setPersonalInfo] = useState({});
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [fieldAnswerMap, setFieldAnswerMap] = useState({});
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    const currentJobApplicationMap = {};
    props.jobPostings.map((jobPosting) => {
      return (currentJobApplicationMap[
        jobPosting.jobPosting.employer.employerId
      ] = {
        ...currentJobApplicationMap[jobPosting.jobPosting.employer.employerId],
        ...generate_field_mapping(jobPosting.jobPosting.fields),
      });
    });
    setFieldAnswerMap(currentJobApplicationMap);
  }, []);

  const generate_field_mapping = (fields) => {
    const fieldMap = {};
    fields.map((field) => {
      fieldMap[field.fieldId] = "";
    });
    return fieldMap;
  };

  const generateJobSpecificQuestions = () => {
    const employers = {};
    props.jobPostings.forEach((jobPosting) => {
      if (!(jobPosting.jobPosting.employer.employerId in employers)) {
        employers[jobPosting.jobPosting.employer.employerId] = {
          ...jobPosting.jobPosting.employer,
          fields: {},
        };
      }
      jobPosting.jobPosting.fields.forEach((field) => {
        if (
          !(
            field.fieldId in
            employers[jobPosting.jobPosting.employer.employerId].fields
          )
        ) {
          employers[jobPosting.jobPosting.employer.employerId].fields[
            field.fieldId
          ] = {
            ...field,
          };
        }
      });
    });

    return Object.keys(employers).map((employerId) => {
      return (
        Object.keys(employers[employerId].fields).length > 0 && (
          <Segment key={employerId}>
            <Header>{employers[employerId].employerName} Questions</Header>
            {Object.keys(employers[employerId].fields).map((fieldId) => {
              return (
                <JobPostingField
                  key={fieldId}
                  id={fieldId}
                  required={!!employers[employerId].fields[fieldId].required}
                  field={employers[employerId].fields[fieldId].field}
                  mappingId={employerId}
                  handleFieldChangeWithMappingId={handleUpdateFieldAnswerMap}
                />
              );
            })}
          </Segment>
        )
      );
    });
  };

  const handleUpdateFieldAnswerMap = (employerId, fieldId, answer) => {
    const currentFieldAnswerMap = { ...fieldAnswerMap };
    currentFieldAnswerMap[employerId][fieldId] = answer;
    setFieldAnswerMap(currentFieldAnswerMap);
  };

  const handleSubmitBulkApplication = () => {
    setIsApplicationLoading(true);
    uploadFiles({
      resume,
      coverLetter,
    })
      .then((fileUploadResponse) => {
        fileUploadResponse.json().then((data) => {
          if (data["hasError"]) {
            throw data["errorMessage"];
          } else {
            const bulkApplicationInput = [];
            props.jobPostings.forEach((jobPosting) => {
              const fieldAnswers = jobPosting.jobPosting.fields.map((field) => {
                if (
                  field.fieldId in
                  fieldAnswerMap[jobPosting.jobPosting.employer.employerId]
                ) {
                  return {
                    id: field.fieldId,
                    answer:
                      fieldAnswerMap[jobPosting.jobPosting.employer.employerId][
                        field.fieldId
                      ],
                  };
                }
              });
              const applicationInput = {
                jobId: jobPosting.jobPosting.id,
                personalInfo,
                fieldAnswers,
              };
              if ("resume" in data.fileLocations) {
                applicationInput["resumeLocation"] = {
                  bucket: data.fileLocations.resume.s3Bucket,
                  key: data.fileLocations.resume.s3Key,
                };
              }
              if ("coverLetter" in data.fileLocations) {
                applicationInput["coverLetterLocation"] = {
                  bucket: data.fileLocations.coverLetter.s3Bucket,
                  key: data.fileLocations.coverLetter.s3Key,
                };
              }
              bulkApplicationInput.push(applicationInput);
            });
            bulkApplyForJobPostings({
              variables: {
                bulkApplicationInput: { applications: bulkApplicationInput },
              },
            })
              .then(({ data }) => {
                props.bulkApplyForJobs(
                  data.bulkApplyForJobPostings.applications
                );
                props.bulkRemoveJobFromCart(
                  data.bulkApplyForJobPostings.applications.map(
                    (application) => {
                      return application.jobPosting.id;
                    }
                  )
                );
                bulkRemoveJobPostingsFromCart({
                  variables: {
                    jobPostingIds: data.bulkApplyForJobPostings.applications.map(
                      (application) => {
                        return application.jobPosting.id;
                      }
                    ),
                  },
                });
                setIsApplicationLoading(false);
                props.setShowCheckoutModal(false);
                props.openToast(
                  getSuccessToastWithMessage(
                    "Successfully checked out from cart"
                  )
                );
              })
              .catch((error) => {
                console.log(error);
                setIsApplicationLoading(false);
                props.setShowCheckoutModal(false);
                props.openToast(
                  getFailureToastWithMessage("Failed to apply for job")
                );
              });
          }
        });
      })
      .catch((error) => {
        console.log(error);
        setIsApplicationLoading(false);
        props.setShowCheckoutModal(false);
        props.openToast(getFailureToastWithMessage("Failed to upload resume"));
      });
  };

  if (Object.keys(props.jobPostings).length === 0) {
    return <Loader active></Loader>;
  } else {
    return (
      <Container>
        <PersonalInfoForm
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          setResume={setResume}
          setCoverLetter={setCoverLetter}
        />
        {generateJobSpecificQuestions()}
        <Segment>
          <Button
            onClick={handleSubmitBulkApplication}
            primary
            fluid
            loading={isApplicationLoading}
            content="Apply"
          ></Button>
        </Segment>
      </Container>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    openToast: (toast) => dispatch({ type: "OPEN_TOAST", payload: toast }),
    bulkApplyForJobs: (applications) =>
      dispatch({
        type: "BULK_APPLY_FOR_JOB_POSTINGS_BY_ID",
        payload: applications,
      }),
    bulkRemoveJobFromCart: (jobPostingIds) =>
      dispatch({ type: "BULK_REMOVE_JOBS_FROM_CART", payload: jobPostingIds }),
  };
};

export default connect(null, mapDispatchToProps)(BulkApplicationView);
