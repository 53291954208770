import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteJobPostingConfirmationModal from "../jobPosting/DeleteJobPostingConfirmationModal";
import EditJobPostingModal from "../jobPosting/EditJobPostingModal";
import JobPostingAccordion from "../jobPosting/JobPostingAccordion";
import {
  NO_JOB_POSTINGS_MESSAGE,
  MY_JOB_POSTINGS_TITLE,
} from "../constants/EmployerConstants";
import { Grid, Card, Container, Header, Dropdown } from "semantic-ui-react";

const EmployerConsole = (props) => {
  const [showEditJobPostingModal, setShowEditJobPostingModal] = useState(false);
  //TODO build the job posting search
  const [myJobPostingSearchInput, setMyJobPostingSearchInput] = useState("");
  const [
    showDeleteJobPostingConfirmationModal,
    setShowDeleteJobPostingConfirmationModal,
  ] = useState(false);
  const [selectedJobPosting, setSelectedJobPosting] = useState({});

  const generateJobPostings = () => {
    return props.jobPostings.length > 0 ? (
      props.jobPostings.map((jobPosting) => {
        return (
          <JobPostingAccordion
            key={jobPosting.id}
            jobPosting={jobPosting}
            setSelectedJobPosting={setSelectedJobPosting}
            setShowEditJobPostingModal={setShowEditJobPostingModal}
            setShowDeleteJobPostingConfirmationModal={
              setShowDeleteJobPostingConfirmationModal
            }
          />
        );
      })
    ) : (
      <Container fluid textAlign="center">
        <Header>{NO_JOB_POSTINGS_MESSAGE}</Header>
      </Container>
    );
  };

  const getMyJobPostingsSearchOptions = () => {
    return props.jobPostings.map((jobPosting) => {
      return {
        key: jobPosting.id,
        text: jobPosting.role,
        value: jobPosting.role,
      };
    });
  };

  const filterForJobPosting = (event, data) => {
    console.log(data.value);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Card fluid>
            <Card.Content>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={3}>
                    <Container>
                      <Header as="h2" content={MY_JOB_POSTINGS_TITLE} />
                    </Container>
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <Dropdown
                      fluid
                      search
                      selection
                      options={getMyJobPostingsSearchOptions()}
                      onChange={filterForJobPosting}
                    ></Dropdown>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
            <Card.Content>{generateJobPostings()}</Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
      {Object.keys(selectedJobPosting).length !== 0 && (
        <Grid>
          <EditJobPostingModal
            employerId={props.employer.employerId}
            jobPosting={selectedJobPosting}
            jobPostingFields={props.jobPostingFields}
            showEditJobPostingModal={showEditJobPostingModal}
            setShowEditJobPostingModal={setShowEditJobPostingModal}
          />
          <DeleteJobPostingConfirmationModal
            jobPosting={selectedJobPosting}
            showDeleteJobPostingConfirmationModal={
              showDeleteJobPostingConfirmationModal
            }
            setShowDeleteJobPostingConfirmationModal={
              setShowDeleteJobPostingConfirmationModal
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    jobPostings: state.employer.jobPostings,
    employer: state.employer,
  };
};

EmployerConsole.propTypes = {
  employer: PropTypes.object.isRequired,
  jobPostingFields: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(EmployerConsole);
