import React, { useState } from "react";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  Item,
  Button,
  Container,
  Segment,
  Grid,
  Header,
  Modal,
} from "semantic-ui-react";
import { REMOVE_JOB_POSTING_FROM_CART_MUTATION } from "../../../services/graphql/mutations/ApplicantMutations";
import {
  getSuccessToastWithMessage,
  getFailureToastWithMessage,
} from "../../shared/toast/ToastOptions";
import BulkApplicationView from "../application/BulkApplicationView";
import {
  getJobPostingHeader,
  formattedJobLocation,
} from "../../shared/jobPostings/JobPostingUtilities";

const ApplicantJobCart = (props) => {
  const [removeJobPostingFromCart, { mutationData }] = useMutation(
    REMOVE_JOB_POSTING_FROM_CART_MUTATION
  );

  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const generateJobPostings = () => {
    return props.jobCart.length > 0 ? (
      props.jobCart.map((job) => {
        return (
          <Item key={job.jobPosting.id}>
            <Item.Content>
              <Item.Header>{getJobPostingHeader(job.jobPosting)}</Item.Header>
              <Item.Description>
                {job.jobPosting.employer.employerName}
              </Item.Description>
              <Item.Meta>{formattedJobLocation(job.jobPosting)}</Item.Meta>
            </Item.Content>
            <Item.Content>
              <Button
                floated="right"
                basic
                color="red"
                onClick={() => {
                  handleRemoveJobFromCart(job.jobPosting.id);
                }}
              >
                Remove
              </Button>
            </Item.Content>
          </Item>
        );
      })
    ) : (
      <Item>No Job Postings in Cart</Item>
    );
  };

  const handleRemoveJobFromCart = (selectedJobPostingId) => {
    removeJobPostingFromCart({
      variables: {
        jobPostingId: selectedJobPostingId,
      },
    })
      .then(() => {
        props.removeJobFromCart(selectedJobPostingId);
        props.openToast(
          getSuccessToastWithMessage("Successfully removed job from cart")
        );
      })
      .catch((error) => {
        console.log(error);
        props.openToast(
          getFailureToastWithMessage("Failed to add job to cart")
        );
      });
  };

  return (
    <Container fluid>
      <Segment>
        <Header>Job Cart</Header>
      </Segment>
      <Segment>
        <Grid columns={2}>
          <Grid.Column width="12">
            <Item.Group divided>{generateJobPostings()}</Item.Group>
          </Grid.Column>
          <Grid.Column width="4">
            {props.jobCart.length > 0 ? (
              <Button
                fluid
                color="green"
                onClick={() => setShowCheckoutModal(true)}
              >
                Checkout
              </Button>
            ) : null}
          </Grid.Column>
        </Grid>
      </Segment>
      <Modal
        size="large"
        open={showCheckoutModal}
        onOpen={() => {
          setShowCheckoutModal(true);
        }}
        onClose={() => {
          setShowCheckoutModal(false);
        }}
      >
        <Modal.Header>Check Out for Jobs</Modal.Header>
        <Modal.Content>
          <BulkApplicationView
            jobPostings={props.jobCart}
            setShowCheckoutModal={setShowCheckoutModal}
          ></BulkApplicationView>
        </Modal.Content>
      </Modal>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    openToast: (toast) => dispatch({ type: "OPEN_TOAST", payload: toast }),
    removeJobFromCart: (jobPostingId) =>
      dispatch({ type: "REMOVE_JOB_FROM_CART", payload: jobPostingId }),
  };
};

const mapStateToProps = (state) => {
  return {
    jobCart: state.applicant.jobCart,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantJobCart);
