export const loadApplicantInfoService = () => {
  return fetch("https://api.applomb.io/load_applicant_info");
};

export const loadApplicantInfoFromId = (accountId) => {
  return fetch("https://api.applomb.io/load_applicant_info_from_id", {
    method: "POST",
    body: JSON.stringify(accountId),
  });
};

export const loadApplicantJobApplicationsFromId = (accountId) => {
  return fetch("https://api.applomb.io/load_applicant_job_applications_from_id", {
    method: "POST",
    body: JSON.stringify(accountId),
  });
};

export const searchJobPostings = (jobPostingSearchInput) => {
  return fetch("https://api.applomb.io/search_job_postings", {
    method: "POST",
    body: JSON.stringify(jobPostingSearchInput),
  });
};

export const uploadFiles = (files) => {
  const data = new FormData();
  if ("resume" in files) {
    data.append("resume", files.resume);
  }
  if ("coverLetter" in files) {
    data.append("cover_letter", files.coverLetter);
  }
  return fetch("https://api.applomb.io/upload_files_to_s3", {
    method: "POST",
    body: data,
  });
};

export const uploadResume = (resume) => {
  const data = new FormData();
  data.append("file", resume);
  return fetch("https://api.applomb.io/upload_resume_to_s3", {
    method: "POST",
    body: data,
  });
};

export const uploadCoverLetter = (coverLetter) => {
  console.log("hey");
  const data = new FormData();
  data.append("file", coverLetter);
  return fetch("https://api.applomb.io/upload_cover_letter_to_s3", {
    method: "POST",
    body: data,
  });
};

export const addPostingToJobCart = (jobCartInput) => {
  return fetch("https://api.applomb.io/add_posting_to_job_cart", {
    method: "POST",
    body: JSON.stringify(jobCartInput),
  });
};

export const loadJobCart = () => {
  return fetch("https://api.applomb.io/load_job_cart");
};

export const checkoutJobCart = () => {
  return fetch("https://api.applomb.io/checkout_job_cart", {
    method: "GET",
  });
};
