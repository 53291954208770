import React from "react";
import PropTypes from "prop-types";
import { Input, Form, Divider, Header } from "semantic-ui-react";

const PersonalInfoForm = (props) => {
  const handleUpdatePersonalInfo = (event) => {
    props.setPersonalInfo({
      ...props.personalInfo,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Form>
      <Header>Personal Information</Header>
      <Form.Group widths="equal">
        <Form.Input
          required
          name="firstName"
          onChange={(event) => handleUpdatePersonalInfo(event)}
          label="First Name"
          placeholder="First Name"
        />
        <Form.Input
          onChange={(event) => handleUpdatePersonalInfo(event)}
          required
          name="lastName"
          label="Last Name"
          placeholder="Last Name"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          onChange={(event) => handleUpdatePersonalInfo(event)}
          required
          name="emailAddress"
          type="email"
          label="Email Address"
          placeholder="Email Address"
        />
        <Form.Input
          onChange={(event) => handleUpdatePersonalInfo(event)}
          required
          name="phoneNumber"
          label="Phone Number"
          placeholder="Phone Number"
        />
      </Form.Group>
      <Input
        type="file"
        name="resume"
        label="Resume"
        onChange={(event) => props.setResume(event.target.files[0])}
      ></Input>
      <Input
        type="file"
        label="Cover Letter"
        name="coverLetter"
        onChange={(event) => props.setCoverLetter(event.target.files[0])}
      ></Input>
      <Divider />
    </Form>
  );
};

PersonalInfoForm.props = {
  personalInfo: PropTypes.object,
  setPersonalInfo: PropTypes.func.isRequired,
  setResume: PropTypes.func.isRequired,
  setCoverLetter: PropTypes.func.isRequired,
};

PersonalInfoForm.defaultProps = {
  personalInfo: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
  },
};

export default PersonalInfoForm;
