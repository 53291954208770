export const getJobPostingHeader = (jobPosting) => {
  return (
    jobPosting?.role +
    (jobPosting?.team !== null ? " | " + jobPosting?.team : "")
  );
};

export const formattedJobLocation = (jobPosting) => {
  return `${jobPosting.location.city}, 
                  ${jobPosting.location.state}`;
};
