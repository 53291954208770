import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Button,
  Container,
  Table,
  Header,
  Segment,
  Modal,
  Form,
  Card,
} from "semantic-ui-react";
import EditPasswordModal from "../../shared/modals/EditPasswordModal";
import { UPDATE_APPLICANT_BASIC_ACCOUNT_INFO_MUTATION } from "../../../services/graphql/mutations/ApplicantMutations";
import {
  getFailureToastWithMessage,
  getSuccessToastWithMessage,
} from "../../shared/toast/ToastOptions";

const ApplicantAccount = (props) => {
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const [isEditPasswordModalOpen, setIsEditPasswordModalOpen] = useState(false);
  const [fields, setFields] = useState({});
  const handleFieldChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };
  const [
    updateApplicantBasicInfo,
    { updateApplicantBasicInfoMutationData },
  ] = useMutation(UPDATE_APPLICANT_BASIC_ACCOUNT_INFO_MUTATION);

  useEffect(() => {
    setFields({
      firstName: props.applicant.firstName,
      lastName: props.applicant.lastName,
      emailAddress: props.applicant.emailAddress,
    });
  }, [props.applicant]);

  const handleUpdateApplicantBasicInfo = () => {
    updateApplicantBasicInfo({
      variables: {
        applicantInfo: {
          id: props.applicant.id,
          ...fields,
        },
      },
    })
      .then(({ data }) => {
        props.updateUserBasicInfo({
          firstName: data.updateApplicantBasicAccountInfo.user.firstName,
          lastName: data.updateApplicantBasicAccountInfo.user.lastName,
          password: data.updateApplicantBasicAccountInfo.user.emailAddress,
        });
        props.openToast(
          getSuccessToastWithMessage("Successfully Saved Account Info")
        );
        setShowEditAccountModal(false);
      })
      .catch((error) => {
        console.log(error);
        props.openToast(
          getFailureToastWithMessage("Failed Saved Account Info")
        );
        setShowEditAccountModal(false);
      });
  };

  return (
    <Container fluid>
      <Segment>
        <Header>Account Information</Header>
        <Button
          onClick={() => {
            setShowEditAccountModal(true);
          }}
        >
          Edit
        </Button>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>First Name</Table.Cell>
              <Table.Cell>{props.applicant.firstName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Last Name</Table.Cell>
              <Table.Cell>{props.applicant.lastName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Email Address</Table.Cell>
              <Table.Cell>{props.applicant.emailAddress}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
      <Segment>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>Password</Table.Cell>
              <Table.Cell>*********</Table.Cell>
              <Table.Cell>
                <Button
                  content="Edit"
                  floated="right"
                  onClick={() => {
                    setIsEditPasswordModalOpen(true);
                  }}
                ></Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
      <Modal
        open={showEditAccountModal}
        onOpen={() => {
          setShowEditAccountModal(true);
        }}
        onClose={() => {
          setShowEditAccountModal(false);
        }}
      >
        <Modal.Header>Edit Basic Account Info</Modal.Header>
        <Modal.Content>
          <Card fluid>
            <Card.Content>
              <Form>
                <Form.Group>
                  <Form.Input
                    required
                    name="firstName"
                    onChange={(event) => {
                      handleFieldChange(event);
                    }}
                    label="First Name"
                    value={fields.firstName}
                  />
                  <Form.Input
                    required
                    name="lastName"
                    onChange={(event) => {
                      handleFieldChange(event);
                    }}
                    label="Last Name"
                    value={fields.lastName}
                  />
                  <Form.Input
                    required
                    name="emailAdressed"
                    onChange={(event) => {
                      handleFieldChange(event);
                    }}
                    label="Email Adress"
                    value={fields.emailAddress}
                  />
                </Form.Group>
              </Form>
              <Button
                content="Save"
                onClick={handleUpdateApplicantBasicInfo}
              ></Button>
            </Card.Content>
          </Card>
        </Modal.Content>
      </Modal>
      <EditPasswordModal
        userId={parseInt(props.applicant.id)}
        isEditPasswordModalOpen={isEditPasswordModalOpen}
        setIsEditPasswordModalOpen={setIsEditPasswordModalOpen}
      ></EditPasswordModal>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    openToast: (toast) => dispatch({ type: "OPEN_TOAST", payload: toast }),
    updateUserBasicInfo: (applicant) =>
      dispatch({ type: "UPDATE_USER_BASIC_INFO", payload: applicant }),
  };
};
const mapStateToProps = (state) => {
  return {
    applicant: state.applicant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantAccount);
