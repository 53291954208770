import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { Container, Item, Segment, Button, Header } from "semantic-ui-react";
import JobPostingField from "./JobPostingField";
import {
  getSuccessToastWithMessage,
  getFailureToastWithMessage,
} from "../../shared/toast/ToastOptions";
import { uploadFiles } from "../../../services/applicant/ApplicantServices";
import { APPLY_FOR_JOB_POSTING } from "../../../services/graphql/mutations/ApplicantMutations";
import PersonalInfoForm from "./PersonalInfoForm";
import {
  getJobPostingHeader,
  formattedJobLocation,
} from "../../shared/jobPostings/JobPostingUtilities";

const ApplicationView = (props) => {
  const [applyForJobPosting, { mutationData }] = useMutation(
    APPLY_FOR_JOB_POSTING
  );
  const [personalInfo, setPersonalInfo] = useState({});
  const [fieldAnswerMap, setFieldAnswerMap] = useState({});
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);

  const handleSubmitApplication = () => {
    uploadFiles({
      resume,
      coverLetter,
    })
      .then((fileUploadResponse) => {
        fileUploadResponse.json().then((data) => {
          if (data["hasError"]) {
            throw data["errorMessage"];
          } else {
            const fieldAnswers = Object.keys(fieldAnswerMap).map((id) => {
              return { id, answer: fieldAnswerMap[id] };
            });
            const applicationInput = {
              jobId: props.jobPosting.id,
              personalInfo,
              fieldAnswers,
            };
            if ("resume" in data.fileLocations) {
              applicationInput["resumeLocation"] = {
                bucket: data.fileLocations.resume.s3Bucket,
                key: data.fileLocations.resume.s3Key,
              };
            }
            if ("coverLetter" in data.fileLocations) {
              applicationInput["coverLetterLocation"] = {
                bucket: data.fileLocations.coverLetter.s3Bucket,
                key: data.fileLocations.coverLetter.s3Key,
              };
            }
            applyForJobPosting({
              variables: {
                applicationInput: applicationInput,
              },
            })
              .then(({ data }) => {
                props.applyForJob(data.applyForJobPosting.application);
                props.setRedirect(true);
                props.openToast(
                  getSuccessToastWithMessage("Successfully applied for job")
                );
              })
              .catch((error) => {
                console.log(error);
                props.openToast(
                  getFailureToastWithMessage("Failed to applied for job")
                );
              });
          }
        });
      })
      .catch((error) => {
        console.log(error);
        props.openToast(
          getFailureToastWithMessage("Failed to applied for job")
        );
      });
  };

  const generateJobPostingFieldQuestions = () => {
    return props.jobPosting?.fields.map((edge) => {
      return (
        <JobPostingField
          key={edge.fieldId}
          id={edge.fieldId}
          required={!!edge.required}
          field={edge.field}
          handleFieldChange={handleUpdateFieldAnswerMap}
        />
      );
    });
  };

  const handleUpdateFieldAnswerMap = (fieldId, answer) => {
    const currentFieldAnswerMap = { ...fieldAnswerMap };
    currentFieldAnswerMap[fieldId] = answer;
    setFieldAnswerMap(currentFieldAnswerMap);
  };

  return (
    <Container>
      <Segment>
        <Item>
          <Button
            onClick={handleSubmitApplication}
            primary
            floated="right"
            content="Apply"
          ></Button>
          <Item.Header>{getJobPostingHeader(props.jobPosting)}</Item.Header>
          <Item.Meta>{props.jobPosting?.employer?.employerName}</Item.Meta>
          <Item.Meta>{formattedJobLocation(props.jobPosting)}</Item.Meta>
        </Item>
      </Segment>
      <Segment> {props.jobPosting?.description}</Segment>
      <Segment>
        <PersonalInfoForm
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          setResume={setResume}
          setCoverLetter={setCoverLetter}
        />
        {props.jobPosting?.fields?.length > 0 ? (
          <Segment>
            <Header>{props.jobPosting?.employer.employerName} Questions</Header>
            {generateJobPostingFieldQuestions()}
          </Segment>
        ) : null}

        <Button
          onClick={() => handleSubmitApplication()}
          primary
          fluid
          content="Apply"
        ></Button>
      </Segment>
    </Container>
  );
};

ApplicationView.props = {
  personalInfo: propTypes.object,
};

ApplicationView.defaultProps = {
  personalInfo: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
  },
};

const mapDispatchToProps = (dispatch) => {
  return {
    openToast: (toast) => dispatch({ type: "OPEN_TOAST", payload: toast }),
    applyForJob: (application) =>
      dispatch({ type: "APPLY_FOR_JOB_POSTING_BY_ID", payload: application }),
  };
};

export default connect(null, mapDispatchToProps)(ApplicationView);
